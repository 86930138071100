import React, { useEffect } from "react";
import {
  Box,
  Image,
  Text,
  VStack,
  Heading,
  Link,
  List,
  ListItem,
  Divider,
} from "@chakra-ui/react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import aboutbanner from "./../Images/banner-image.png";

const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box position="fixed" top="0" left="0" right="0" zIndex="sticky">
        <Navbar />
      </Box>

      <Box marginTop="100px" padding="19px">
        <VStack spacing="8" align="stretch" maxWidth="1200px" margin="auto">
          {/* Overview Section */}
          <Heading size="lg" marginBottom="4" textAlign="left">
            About US{" "}
          </Heading>
          <Divider />
          <Box>
            <Heading size="lg" marginBottom="4" textAlign="left">
              Overview of Jeevan Jyoti
            </Heading>
            <Text fontSize="md" lineHeight="tall" textAlign="left">
              Established in 1996,{" "}
              <strong>Jeevan Jyoti Divyang Seva Sanstha</strong> is dedicated to
              empowering deaf and mute children through education, support, and
              advocacy. Our organization strives to create an inclusive
              environment where these children can thrive and develop essential
              skills for a brighter future.
            </Text>
          </Box>

          {/* Mission Section */}
          <Box>
            <Heading size="lg" marginBottom="4" textAlign="left">
              Mission
            </Heading>
            <Text fontSize="md" lineHeight="tall" textAlign="left">
              To provide comprehensive educational programs, resources, and
              support services to children with hearing and speech impairments,
              fostering their personal and social development.
            </Text>
          </Box>

          {/* Vision Section */}
          <Box>
            <Heading size="lg" marginBottom="4" textAlign="left">
              Vision
            </Heading>
            <Text fontSize="md" lineHeight="tall" textAlign="left">
              We envision a society where every deaf and mute child has access
              to quality education and the opportunity to lead a fulfilling
              life.
            </Text>
          </Box>

          {/* Programs and Services Section */}
          <Box>
            <Heading size="lg" marginBottom="4" textAlign="left">
              Programs and Services
            </Heading>
            <List spacing="3" textAlign="left">
              <ListItem>
                <strong>Educational Support:</strong> Tailored learning programs
                designed to meet the unique needs of each child.
              </ListItem>
              <ListItem>
                <strong>Counseling and Guidance:</strong> Professional
                counseling for children and their families to enhance emotional
                well-being.
              </ListItem>
              <ListItem>
                <strong>Community Outreach:</strong> Raising awareness about the
                challenges faced by deaf and mute children to promote
                inclusivity and understanding.
              </ListItem>
              <ListItem>
                <strong>Skill Development Workshops:</strong> Offering workshops
                that focus on life skills and vocational training.
              </ListItem>
            </List>
          </Box>

          {/* Achievements Section */}
          <Box>
            <Heading size="lg" marginBottom="4" textAlign="left">
              Achievements
            </Heading>
            <Text fontSize="md" lineHeight="tall" textAlign="left">
              Over the years, Jeevan Jyoti Divyang Seva Sanstha has touched the
              lives of countless children and their families, enabling them to
              overcome obstacles and reach their full potential. Our impact is
              reflected in the success stories of our graduates who have gone on
              to pursue higher education and meaningful careers.
            </Text>
          </Box>

          {/* Get Involved Section */}
          <Box>
            <Heading size="lg" marginBottom="4" textAlign="left">
              Get Involved
            </Heading>
            <Text fontSize="md" lineHeight="tall" textAlign="left">
              Join us in our mission to empower deaf and mute children. Your
              support can make a significant difference in their lives. Whether
              through donations, volunteering, or spreading the word about our
              cause, every action counts.
            </Text>
            <Text
              fontSize="md"
              lineHeight="tall"
              marginTop="4"
              textAlign="left"
            >
              <strong>Contact Us:</strong>
              <br />
              <strong>Phone:</strong> 9304497378
              <br />
              <strong>Email:</strong>{" "}
              <Link
                href="mailto:Jeevanjyotiviklangsevasanstha@gmail.com"
                color="blue.500"
              >
                Jeevanjyotiviklangsevasanstha@gmail.com
              </Link>
            </Text>
          </Box>
        </VStack>
      </Box>
      <Footer />
    </>
  );
};

export default Aboutus;
