import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  GridItem,
  Image,
  Text,
  Heading,
  VStack,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import galrybanner from "./../Images/banner-image.png";

// Import images
import image1 from "./../Images/image1.jpg";
import image2 from "./../Images/imag2.jpg";
import image3 from "./../Images/image3.jpg";
import image4 from "./../Images/image4.jpg";
import image5 from "./../Images/image5.jpg";
import image6 from "./../Images/image6.jpg";
import image7 from "./../Images/image7.jpg";
import image8 from "./../Images/image8.jpg";
import image9 from "./../Images/image9.jpg";
import image10 from "./../Images/image10.jpg";
import image11 from "./../Images/image11.jpg";
import image12 from "./../Images/image12.jpg";
import image13 from "./../Images/image13.jpg";
import image14 from "./../Images/image14.jpg";
import image15 from "./../Images/image15.jpg";

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Array of images
  const galleryImages = [
    { src: image1 },
    { src: image2 },
    { src: image3 },
    { src: image4 },
    { src: image5 },
    { src: image6 },
    { src: image7 },
    { src: image8 },
    { src: image9 },
    { src: image10 },
    { src: image11 },
    { src: image12 },
    { src: image13 },
    { src: image14 },
    { src: image15 },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Function to handle image click
  const handleImageClick = (src) => {
    setSelectedImage(src);
    onOpen();
  };

  return (
    <>
      <Navbar />

      <Box>
        {/* Header Section */}
        <Box padding="20px">
          <Heading size="lg" textAlign="left">
            Trust Activities Gallery
          </Heading>
          <Text fontSize="md" textAlign="left" color="gray.600">
            A glimpse into the work done by our organization
          </Text>
          <Heading size="lg" marginBottom="4" textAlign="left">
            Jeevan Jyoti Divyang Seva Sanstha{" "}
          </Heading>
          <Text fontSize="md" lineHeight="tall" textAlign="left">
            Established in 1996,{" "}
            <strong>Jeevan Jyoti Divyang Seva Sanstha</strong> is dedicated to
            empowering deaf and mute children through education, support, and
            advocacy. Our organization strives to create an inclusive
            environment where these children can thrive and develop essential
            skills for a brighter future.
          </Text>
        </Box>
        <Divider />

        {/* Responsive Grid */}
        <Grid
          templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}
          gap={4}
          padding={1}
        >
          {galleryImages.map((image, index) => (
            <GridItem
              key={index}
              overflow="hidden"
              position="relative"
              borderRadius="md"
              boxShadow="lg"
              _hover={{
                transform: "scale(1.05)",
                transition: "transform 0.3s ease-in-out",
              }}
              onClick={() => handleImageClick(image.src)} // Set image on click
            >
              <Image
                src={image.src}
                width="100%"
                height="200px"
                objectFit="cover"
                borderRadius="md"
                cursor="pointer"
              />
            </GridItem>
          ))}
        </Grid>
      </Box>

      {/* Modal for Full-Screen Image */}
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent bg="black">
          <ModalCloseButton color="white" />
          <ModalBody
            display="flex"
            alignItems="center"
            justifyContent="center"
            p="0"
          >
            <Image
              src={selectedImage}
              alt="Full-screen view"
              objectFit="contain"
              maxH="100vh"
              maxW="100vw"
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Footer />
    </>
  );
};

export default Gallery;
