import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="footer-container">
          <div className="row" style={{ width: "100%" }}>
            <div className="col-md-3">
              <div className="test-series">
                <h2>Our Mission</h2>
                <p>
                  We are dedicated to fostering growth, trust, and community
                  development. Join us in making a meaningful impact.
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="footer-section about-us">
                <h3>About Us</h3>
                <p>
                  We are a trusted organization committed to providing support,
                  resources, and opportunities to help individuals and
                  communities thrive.
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="footer-section support">
                <h3>Support</h3>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/gallery">Gallery</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2">
              <div className="footer-section follow-us">
                <h3>Follow Us</h3>
                <ul className="social-media">
                  <li>
                    <a
                      href="https://facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://linkedin.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-section contact">
                <h3>Contact</h3>
                <p>
                  <strong>Mobile:</strong> <br />
                  <a href="tel:+919304497378">+91 9304497378</a>,{" "}
                  <a href="tel:+916203486082">+91 6203486082</a>
                </p>
                <p>
                  <strong>Email:</strong> <br />
                  <Link to="mailto:Jeevanjyotiviklangsevasanstha@gmail.com ">
                    Jeevanjyotiviklangsevasanstha@gmail.com{" "}
                  </Link>
                </p>
                <p>
                  <strong>Location:</strong> <br />
                  Jeevan Jyoti Divyang Seva SANSTHA, Fair Field Colony, Digha
                  Ghat, Patna, Bihar 800011, India
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <p>&copy; 2024 [Trust Name]. All Rights Reserved.</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
