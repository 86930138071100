import React from "react";
import {
  Box,
  Grid,
  GridItem,
  Image,
  Heading,
  Text,
  VStack,
  Button,
  Input,
  HStack,
  Divider,
  Center,
} from "@chakra-ui/react";
import Navbar from "./../Navbar/Navbar";
import Footer from "./../Footer/Footer";

import heroImage from "./../Images/image6.jpg"; // Replace with your actual image path
import aboutImage from "./../Images/image3.jpg";
import trustImage1 from "./../Images/image11.jpg";
import trustImage2 from "./../Images/image3.jpg";
import trustImage3 from "./../Images/image8.jpg";
import backgroundImage from "./../Images/image7.jpg";

function Home() {
  return (
    <>
      <Navbar />
      <Heading size="lg" textAlign="center" fontFamily="emoji" marginTop="15px">
        Welcome to Our Trust
      </Heading>
      <Heading size="lg" textAlign="center">
        Jeevan Jyoti Divyang Seva Sanstha
      </Heading>
      <Box
        bg="teal.600"
        color="white"
        py="2"
        position="relative"
        overflow="hidden"
        textAlign="center"
        marginTop="20px"
      >
        <Box
          display="inline-block"
          whiteSpace="nowrap"
          animation="scrollingText 20s linear infinite"
          fontSize={{ base: "lg", md: "2xl" }}
          fontWeight="bold"
        >
          Jeevan Jyoti Divyang Seva Sanstha - Empowering Lives, Inspiring
          Futures
        </Box>

        {/* CSS Animation */}
        <style>
          {`
      @keyframes scrollingText {
        0% {
          transform: translateX(100%);
        }
        50% {
          transform: translateX(-100%);
        }
        100% {
          transform: translateX(100%);
        }
      }
    `}
        </style>
      </Box>

      {/* Hero Section */}
      <Box bg="gray.100" p="8">
        <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
          <VStack align="center" spacing={4}>
            {" "}
            {/* Align content to the center */}
            <Text size="20px" textAlign="center" fontWeight="700">
              Reg No: 797/2003-04 | Est: 1996
            </Text>
            <Text fontSize="md" color="gray.600" textAlign="center">
              Join us in making a difference by empowering children through
              education and supporting communities in need.
            </Text>
            <Button colorScheme="teal" alignSelf="center">
              Learn More
            </Button>{" "}
            {/* Center button */}
          </VStack>
          <Image
            src={heroImage}
            alt="Hero Image"
            objectFit="cover"
            borderRadius="md"
          />
        </Grid>
      </Box>

      {/* About Section */}
      <Box bg="white" p="8">
        <Heading size="lg" textAlign="center" mb="6">
          About Our Trust
        </Heading>
        <Text fontSize="md" color="gray.600" textAlign="center" mb="6">
          Established in 1996, Jeevan Jyoti Divyang Seva Sanstha is dedicated to
          empowering deaf and mute children through education, support, and
          advocacy. Our organization strives to create an inclusive environment
          where these children can thrive and develop essential skills for a
          brighter future.
        </Text>

        <Image
          src={aboutImage}
          alt="About Image"
          width="100%"
          height="400px"
          objectFit="cover"
          borderRadius="md"
        />
      </Box>

      {/* Alternating Layout Sections */}
      {[trustImage1, trustImage2, trustImage3].map((image, index) => (
        <Box bg={index % 1 === 0 ? "gray.50" : "white"} p="8" key={index}>
          <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
            {index % 2 === 0 ? (
              <>
                <Image
                  src={image}
                  alt={`Trust Section ${index + 1}`}
                  objectFit="cover"
                  borderRadius="md"
                />
                <Box align="start" spacing={4}>
                  <Heading size="md" textAlign="center">
                    Our Work
                  </Heading>
                  <Text textAlign="center" fontSize="sm" color="gray.600">
                    We focus on empowering underprivileged children through
                    education, ensuring their bright future.
                  </Text>
                </Box>
              </>
            ) : (
              <>
                <Box align="start" spacing={4}>
                  <Heading size="md" textAlign="center">
                    Mission
                  </Heading>
                  <Text fontSize="sm" textAlign="center" color="gray.600">
                    To provide comprehensive educational programs, resources,
                    and support services to children with hearing and speech
                    impairments, fostering their personal and social
                    development.
                  </Text>
                </Box>
                <Image
                  src={image}
                  alt={`Trust Section ${index + 1}`}
                  objectFit="cover"
                  borderRadius="md"
                />
              </>
            )}
          </Grid>
        </Box>
      ))}

      <Heading size="lg" textAlign="center" mb="6">
        Achievements{" "}
      </Heading>
      <Text fontSize="md" color="gray.600" textAlign="center" mb="6">
        To provide comprehensive educational programs, resources, and support
        services to children with hearing and speech impairments, fostering
        their personal and social development.Over the years, Jeevan Jyoti
        Divyang Seva Sanstha has touched the lives of countless children and
        their families, enabling them to overcome obstacles and reach their full
        potential. Our impact is reflected in the success stories of our
        graduates who have gone on to pursue higher education and meaningful
        careers.
      </Text>

      {/* Call-to-Action Section */}
      <Box
        bgImage={`url(${backgroundImage})`}
        bgSize="cover"
        bgPos="center"
        color="white"
        p="16"
        textAlign="center"
      >
        <Heading size="lg" mb="4">
          Join the community to give education for children
        </Heading>
        <Button colorScheme="teal" size="lg" mb="6">
          Get Started
        </Button>
        <Text fontSize="md" color="whiteAlpha.800">
          How Can You Help? Your donation will help us save and improve lives
          with research, education, and emergency care.
        </Text>
        <HStack justifyContent="center" mt="6">
          <Button colorScheme="green" size="md">
            Donate Now
          </Button>
          <Button colorScheme="blue" size="md">
            Contact Us
          </Button>
        </HStack>
      </Box>

      {/* Newsletter Section */}
      <Box bg="gray.100" p="8">
        <VStack spacing={4} textAlign="center">
          <Heading size="md">
            To get weekly & monthly news, Subscribe to our newsletter.
          </Heading>
          <HStack>
            <Input placeholder="Enter your email" size="md" />
            <Button colorScheme="teal" size="md">
              Subscribe
            </Button>
          </HStack>
        </VStack>
      </Box>

      <Footer />
    </>
  );
}

export default Home;
