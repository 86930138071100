import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormLabel,
  Input,
  Image,
  VStack,
  HStack,
  Text,
  Divider,
  Link as ChakraLink,
  Heading,
  SimpleGrid,
  Center,
} from "@chakra-ui/react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
  FaPhoneAlt,
} from "react-icons/fa";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import conactbanner from "./../Images/banner-image.png";

const Conact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box position="fixed" top="0" left="0" right="0" zIndex="sticky">
        <Navbar />
      </Box>

      <Box marginTop="100px">
        <Heading size="lg" marginBottom="4" textAlign="left">
          Contact Us{" "}
        </Heading>
        {/* Trust Details Section */}
        <VStack align="start" spacing={4} padding={6}>
          <Heading size="lg">Jeevan Jyoti Divyang Seva Sanstha</Heading>
          <Text>Reg No: 797/2003-04 | Est: 1996</Text>
          <Text>Secretary Name: Rameshwar Prasad</Text>
        </VStack>

        <Divider />

        {/* Contact Buttons Section */}
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-4">
            {" "}
            <Button
              as={ChakraLink}
              href="tel:+919304497378"
              leftIcon={<FaPhoneAlt />}
              colorScheme="teal"
              marginBottom={1}
            >
              Call: +91 9304497378
            </Button>
          </div>
          <div className="col-md-4">
            {" "}
            <Button
              as={ChakraLink}
              href="mailto:Jeevanjyotiviklangsevasanstha@gmail.com"
              leftIcon={<FaPhoneAlt />}
              colorScheme="teal"
              marginBottom={1}
            >
              Email: Jeevanjyotiviklangsevasanstha@gmail.com
            </Button>
          </div>
          <div className="col-md-4">
            {" "}
            <Button
              as={ChakraLink}
              href="https://wa.me/916203486082"
              leftIcon={<FaWhatsapp />}
              colorScheme="green"
              marginBottom={1}
            >
              Chat on WhatsApp
            </Button>
          </div>
        </div>
        <HStack justify="start" spacing={4} padding={4}></HStack>

        <Divider />

        {/* Social Media Links */}
        <Center>
          {" "}
          <HStack justify="center" spacing={6} padding={4}>
            <ChakraLink href="/" isExternal>
              <FaFacebookF size={24} />
            </ChakraLink>
            <ChakraLink href="/" isExternal>
              <FaTwitter size={24} />
            </ChakraLink>
            <ChakraLink href="/" isExternal>
              <FaInstagram size={24} />
            </ChakraLink>
            <ChakraLink href="/" isExternal>
              <FaLinkedinIn size={24} />
            </ChakraLink>
          </HStack>
        </Center>
        <Divider />

        {/* Map and Contact Form Section */}
        <SimpleGrid columns={[1, 2]} spacing={6} padding={4}>
          {/* Map */}
          <Box>
            <Heading size="md" mb={4}>
              Our Location
            </Heading>
            <Text mb={2}>
              Jeevan Jyoti Divyang Seva SANSTHA, Fair Field Colony, Digha Ghat,
              Patna, Bihar 800011, India{" "}
            </Text>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.789574452594!2d85.10827907493103!3d25.632984271472112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed578d4df12f1b%3A0xdf4321234f123b!2s78%2C%20Fair%20Field%20Colony%2C%20Digha%20Ghat%2C%20Patna%2C%20Bihar%20800011%2C%20India!5e0!3m2!1sen!2sin!4v1234567890123"
              width="100%"
              height="300"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </Box>

          {/* Contact Form */}
          <Box>
            <Heading size="md" mb={4}>
              Send Us Your Feedback
            </Heading>
            <FormLabel>Name</FormLabel>
            <Input type="text" placeholder="Enter your name" />
            <FormLabel>Email</FormLabel>
            <Input type="email" placeholder="Enter your email" />
            <FormLabel>Mobile</FormLabel>
            <Input type="tel" placeholder="Enter your mobile" />
            <FormLabel>Message</FormLabel>
            <textarea
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
              rows="4"
              placeholder="Enter your message"
            ></textarea>
            <Button colorScheme="green" width="100%">
              Send Feedback
            </Button>
          </Box>
        </SimpleGrid>
      </Box>

      <Footer />
    </>
  );
};

export default Conact;
