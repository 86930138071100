import {
  Box,
  Button,
  useDisclosure,
  useBreakpointValue,
  Text,
} from "@chakra-ui/react";
import { PiDotsThreeCircleLight } from "react-icons/pi";
import { Link } from "react-router-dom";

const Navbar = () => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <nav style={{ background: "floralwhite" }}>
      <Box
        display="flex"
        alignItems="center"
        padding={4}
        justifyContent="space-between"
      >
        {/* Replace Logo with Text */}
        <Link to="/">
          <Text
            fontSize="xl"
            fontWeight="bold"
            color="#ff0000"
            fontFamily="cursive"
            _hover={{ textDecoration: "none", color: "teal" }}
          >
            Jeevan Jyoti Divyang Seva Sanstha
          </Text>
        </Link>
        {isMobile ? (
          <Box display="flex" alignItems="center">
            <PiDotsThreeCircleLight
              onClick={onToggle}
              style={{
                height: "50px",
                width: "50px",
                color: "black",
                cursor: "pointer",
              }}
            />
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <Box display="flex" gap={4}>
              <Link to="/">
                <Button marginRight={2} colorScheme="" color="black">
                  Home
                </Button>
              </Link>

              <Link to="/about">
                <Button marginRight={2} colorScheme="" color="black">
                  About
                </Button>
              </Link>
              <Link to="/contact">
                <Button marginRight={2} colorScheme="" color="black">
                  Contact
                </Button>
              </Link>
              <Link to="/gallery">
                <Button marginRight={2} colorScheme="" color="black">
                  Gallery
                </Button>
              </Link>
            </Box>
          </Box>
        )}
      </Box>
      {isOpen && (
        <Box
          display={{ base: "block", md: "none" }}
          position="absolute"
          top="60px"
          right="0"
          bg="floralwhite"
          width="100%"
          p={4}
          boxShadow="md"
        >
          <Link to="/">
            <Button
              colorScheme="green"
              marginBottom={2}
              width="47%"
              onClick={onClose}
            >
              Home
            </Button>
          </Link>

          <Link to="/about">
            <Button
              colorScheme="green"
              marginBottom={2}
              width="47%"
              onClick={onClose}
              marginLeft="3px"
            >
              About
            </Button>
          </Link>
          <Link to="/contact">
            <Button
              colorScheme="green"
              marginBottom={2}
              width="47%"
              onClick={onClose}
            >
              Contact
            </Button>
          </Link>
          <Link to="/gallery">
            <Button
              colorScheme="green"
              marginBottom={2}
              width="47%"
              onClick={onClose}
              marginLeft="3px"
            >
              Gallery
            </Button>
          </Link>
        </Box>
      )}
    </nav>
  );
};

export default Navbar;
